import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en/translation.json";
import tr from "./tr/translation.json";

const detectionOptions = {
  order: ["cookie", "localStorage", "navigator", "htmlTag"],
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  caches: ["localStorage", "cookie"],
  checkWhitelist: true,
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: "en",
    fallbackLng: "en",
    debug: true,
    supportedLngs: ["en", "tr"],
    resources: {
      en: { translation: en },
      tr: { translation: tr },
    },
    detection: detectionOptions,
  });
