export const getFileType = (lectureUrl) => {
  const extension = lectureUrl.split(".").pop();

  switch (extension) {
    case "mp4":
    case "avi":
    case "mov":
      return "video";
    case "mp3":
    case "wav":
      return "audio";
    case "doc":
    case "docx":
    case "pdf":
    case "ppt":
    case "pptx":
    case "xls":
    case "xlsx":
      return "document";
    default:
      return "unknown";
  }
};

export const isValueNegative = (value) => Math.sign(value) === -1;

export const indicateNumberSign = (value) => {
  if (value && isValueNegative(value)) {
    value = value?.replace("-", "");
    return `(${value}%)`;
  }
  return `${value || 0}%`;
};

export const joinWithAnd = (weaknessArray) => {
  if (weaknessArray.length === 0) {
    return "";
  } else if (weaknessArray.length === 1) {
    return weaknessArray[0];
  } else if (weaknessArray.length === 2) {
    return weaknessArray.join(" & ");
  } else {
    return (
      weaknessArray.slice(0, -1).join(", ") +
      " & " +
      weaknessArray[weaknessArray.length - 1]
    );
  }
};

export const containsHTML = (str: string) => {
  const htmlRegex = /<[^>]*>/g;
  return htmlRegex.test(str);
};
